// Imports
// ------
import styled, { css } from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { breakup } from '@tackl';

// Exports
// ------
const buttonStyles = css`
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: ${(props) => (props.isFluid ? `center` : `center`)};
	border: 1px solid ${(props) => props.theme.bc1};
	border-radius: 2.4rem;
	background: ${(props) => props.theme.bc2};
	padding: 1.2rem ${(props) => (props.hasExtraPad ? `6rem` : `2.4rem`)};
	text-transform: uppercase;
	font-family: ${(props) => props.theme.body};
	font-size: 1.2rem;
	line-height: 1.4rem;
	color: ${(props) => props.theme.bc1};
	transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
		border-color 0.3s ease-in-out, border 0.3s ease-in-out;

	${(props) => props.isFluid && `width: 100%;`};
	&.fluid { width: 100%; }

	${breakup.medium`
		${(props) => props.isFluidMobileOnly && `width: auto;`};
	`}

	&:hover {
		background: ${(props) => props.theme.bc1};
		color: ${(props) => props.theme.bc2};
	}
`;

export const JacketButton = styled.button`
	${buttonStyles}
`;

export const JacketLink = styled(AniLink)`
	${buttonStyles}

	justify-content: ${(props) => (props.isfluid ? `center` : `center`)};
	padding: 1.2rem ${(props) => (props.hasextrapad ? `6rem` : `2.4rem`)};
	${(props) => props.isfluid && `width: 100%;`};
`;
