// Imports
// ------
import { css } from "styled-components";
import { breakup } from '@tackl';

// Exports
// ------
const breakpoints = css`
    /* The logic... */
    /* 100% width / 12 columns * Entered columns */

    ${props => props.small && css`
        width: calc(100% / 12 * ${props.small});
        flex-grow: 0;
    `}

    ${props => props.smedium && css`
        ${breakup.smedium`
            width: calc(100% / 12 * ${props.smedium});
            flex-grow: 0;
        `}
    `}

    ${props => props.medium && css`
        ${breakup.medium`
            width: calc(100% / 12 * ${props.medium});
            flex-grow: 0;
        `}
    `}

    ${props => props.large && css`
        ${breakup.large`
            width: calc(100% / 12 * ${props.large});
            flex-grow: 0;
        `}
    `}

    ${props => props.xlarge && css`
        ${breakup.xlarge`
            width: calc(100% / 12 * ${props.xlarge});
            flex-grow: 0;
        `}
    `}

    ${props => props.xxlarge && css`
        ${breakup.xxlarge`
            width: calc(100% / 12 * ${props.xxlarge});
            flex-grow: 0;
        `}
    `}

    ${props => props.huge && css`
        ${breakup.huge`
            width: calc(100% / 12 * ${props.huge});
            flex-grow: 0;
        `}
    `}

    ${props => props.uber && css`
        ${breakup.uber`
            width: calc(100% / 12 * ${props.uber});
            flex-grow: 0;
        `}
    `}
`;

export default breakpoints;