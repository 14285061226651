// Imports
// -------
import React from 'react';
import { lightDarkMode } from '@states/darkmode';
import { themeStyles } from '@theme';
import { observer } from 'mobx-react';

// Styles
// -------
import { JacketButton, JacketLink } from './styles';

// Component
// -------
const Button = ({
	type,
	to,
	children,
	onClick,
	className,
	fluid,
	fluidMobileOnly,
	extraPad,
	innerRef,
	aria,
	...props
}) => {
	if (type === `button`)
		return (
			<JacketButton
				type='button'
				onClick={onClick}
				className={className}
				isFluid={fluid}
				isFluidMobileOnly={fluidMobileOnly}
				hasExtraPad={extraPad}
				ref={innerRef}
				aria-label={aria}
				{...props}>
				{children}
			</JacketButton>
		);

	if (type === `link`)
		return (
			<JacketLink
				cover
				direction='up'
				bg={lightDarkMode.isDark ? themeStyles.bc2 : themeStyles.bc1}
				to={to}
				className={className}
				isfluid={fluid}
				hasextrapad={extraPad}
				ref={innerRef}
				aria-label={aria}
				{...props}>
				{children}
			</JacketLink>
		);
	else
		console.warn(
			'You didnt choose a prop for your button, you have "Button" or "Link"'
		);
};

export default observer(Button);
