// Imports
// ------
import { css } from "styled-components";
import { breakup } from '@tackl';

// Exports
// ------
const offset = css`
    ${props => props.offsetOnSmall && css`
        margin-left: calc(100% / 12 * ${props.offsetOnSmall});
    `}

    ${props => props.offsetOnSmedium && css`
        ${breakup.smedium`
            margin-left: calc(100% / 12 * ${props.offsetOnSmedium});
        `}
    `}

    ${props => props.offsetOnMedium && css`
        ${breakup.medium`
            margin-left: calc(100% / 12 * ${props.offsetOnMedium});
        `}
    `}
    
    ${props => props.offsetOnLarge && css`
        ${breakup.large`
            margin-left: calc(100% / 12 * ${props.offsetOnLarge});
        `}
    `}

    ${props => props.offsetOnXLarge && css`
        ${breakup.xlarge`
            margin-left: calc(100% / 12 * ${props.offsetOnXLarge});
        `}
    `}

    ${props => props.offsetOnXLarge && css`
        ${breakup.xlarge`
            margin-left: calc(100% / 12 * ${props.offsetOnXLarge});
        `}
    `}

    ${props => props.offsetOnXXLarge && css`
        ${breakup.xxlarge`
            margin-left: calc(100% / 12 * ${props.offsetOnXXLarge});
        `}
    `}

    ${props => props.offsetOnHuge && css`
        ${breakup.huge`
            margin-left: calc(100% / 12 * ${props.offsetOnHuge});
        `}
    `}

    ${props => props.offsetOnUber && css`
        ${breakup.uber`
            margin-left: calc(100% / 12 * ${props.offsetOnUber});
        `}
    `}
`;

export default offset;